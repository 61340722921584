<template>
  <div class="store-wrap" :class="{ 'store-wrap-pc': isPc }">
    <!-- banner -->
    <div class="banner-common" :class="{ 'banner-pc-common': isPc }">
      <img src="../../assets/img/store.png" v-if="!isPc" alt="" />
      <img src="../../assets/img-pc/store-pc.png" v-else alt="" />
      <div class="text">
        <span class="chanese">加盟连锁店风采</span>
        <span class="english">Style of franchise store</span>
      </div>
    </div>

    <!-- content -->
    <div class="content-wrap">
      <title-module v-if="isPc" :titleInfo="titleInfo" :isPc="isPc" />
      <list :id="id" :isPc="isPc" />
    </div>
  </div>
</template>

<script>
import list from "./list";
import titleModule from "@/components/title";
export default {
  name: "store-style",
  props: ["isPc"],
  components: {
    list,
    titleModule
  },
  data() {
    return {
      id: "",
      titleInfo: {
        titleRed: "加盟",
        titleWhite: "连锁店风采",
        remark: "STYLE OF FRANCHISE STORES"
      }
    };
  },

  methods: {
    getId() {
      const path = this.$route.path;
      let menueList = localStorage.getItem("mobileMenueInfo");
      menueList = menueList ? JSON.parse(menueList) : [];
      menueList.length &&
        menueList.forEach(e => {
          if (e.path === path) {
            this.id = e.id;
          }
        });
    }
  },

  created() {
    this.getId();
  }
};
</script>

<style lang="less" scoped>
@import "./storeStylePc.less";
</style>
