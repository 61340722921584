<template>
  <div>
    <van-list
      :immediate-check="false"
      v-model="loading"
      :finished="finished"
      :finished-text="finishedText"
      @load="onLoad"
    >
      <div class="img-container">
        <div class="img-content" v-for="item in listData" :key="item.id">
          <div class="img-wrap">
            <img :src="item.mainPicture" alt="" />
          </div>
          <span>{{ item.title }}</span>
        </div>
      </div>
    </van-list>
    <!-- <van-image-preview v-model="previewShow" :images="imageUrls" :startPosition="startPosition">
      <template v-slot:index>{{ previewTitle }}</template>
    </van-image-preview> -->
  </div>
</template>

<script>
import { getFeatureContent } from "@/api/index.js";
export default {
  props: ["id", "isPc"],
  data() {
    return {
      loading: false,
      finished: false,
      pageNumber: 1,
      pageSize: 8,
      sort: "time",
      order: "asc",
      listData: [],
      finishedText: this.isPc ? "" : "暂时没有更多了"
      //   previewShow: false,
      //   imageUrls: [],
      //   startPosition: 0,
      //   previewTitle: ""
    };
  },

  methods: {
    async onLoad() {
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        sort: this.sort,
        order: this.order,
        navigationId: this.id
      };
      try {
        const { result } = await getFeatureContent(params);
        const data = result ? result.content : [];
        this.listData.push(...data);
        this.loading = false;
        this.pageNumber = this.pageNumber + 1;
        if (this.pageNumber > result.totalPages) this.finished = true;
      } catch (error) {
        console.log(error);
        this.$message.error("获取内容失败！");
        this.finished = true;
      }
    }
  },

  created() {
    this.loading = true;
    this.onLoad();
  }
};
</script>

<style lang="less" scoped>
.img-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 50px 47px;
  .img-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .img-wrap {
      display: flex;
      border-radius: 10px;
      justify-content: center;
      img {
        width: 501px;
        height: 390px;
      }
    }
    span {
      font-size: 30px;
      font-weight: bold;
      line-height: 30px;
      text-align: center;
      color: #333333;
      margin: 30px 0;
    }
  }
}
/deep/.van-list__finished-text {
  font-weight: bold;
  color: #999999;
}
</style>
